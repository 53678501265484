import { attr, css, customElement, FASTElement, html, observable, ref, repeat, when } from "@microsoft/fast-element";
import { state, storeState } from "./store";
import { Message, query } from "./api";
import closeIcon from './icons/xmark-solid.svg';
import downIcon from './icons/angle-down-solid.svg';
import { BubbleComponent } from "./bubble";

const template = html<ChatComponent>`
<div class="wrapper ${(x) => (x.visible ? " visible" : "hidden" )}">
  <button class="close" @click="${x => x.bubble.isOpen = false}">
    <img src="${x => closeIcon}" />
  </button>
  <h2>Jugendurlaub-Bot</h2>
  <section class="chat" ${ref("chatElement")} @scroll=${(x, c) => x.onScroll()} >
    ${repeat(x => x.state.messages, html<Message>`
      <message-element :msg="${x => x}"></message-element>
      `)}
      ${when(y => y.state.loading, html<ChatComponent>`<loading-indicator></loading-indicator>`)}
  </section>
  ${
  when(x => !x.isAtBottom, html<ChatComponent>`
  <button id="scroll-to-bottom" @click="${x => x.scrollToLatest()}"><img src="${x => downIcon}" /></button>
      `)
  }
  <form @submit=${(x, c) => x.onSubmit(c.event)}>
    <input
      placeholder="Was möchtest du über den Jugendurlaub wissen?"
      ?disabled=${x => x.state.loading}
      @input=${(x, c) => x.onInputChange(c.event)}
      name="question" 
    />
    <button ${ref("input")} ?disabled=${x => x.msg == "" || x.state.loading} type="submit">Absenden</button>
  </form>
</div>
`;

const styles = css`
.wrapper {
    max-width: 520px;
    width: 520px;
    box-shadow: 0 0 50px rgb(var(--black) / 10%);
    background: white;
    position: fixed;
    right: 30px;
    top: 100vh;
    z-index: 100;
    height: min(670px, -19px + 100vh);
    display: grid;
    grid-template-areas:
      "title close"
      "chat chat"
      "form form";
    grid-template-rows: auto 1fr auto;
    grid-template-columns: minmax(10px, 1fr) minmax(10px, 1fr);
    transition: all 200ms ease-out;
    opacity: 0;
  }

  @media (max-width: 620px) {
    .wrapper {
      right: 3px;
      width: calc(100vw - 6px);
      max-width: calc(100vw - 6px);
    }
  }

  .wrapper.visible {
    opacity: 1;
    top: max(10px, -687px + 100vh);
  }

  button.close {
    grid-area: close;
    font-size: 1rem;
    justify-self: end;
    align-self: start;
    background: none;
    border: none;
    cursor: pointer;
  }
  
  h2 {
    grid-area: title;
    margin: 0;
    font-size: 1.1rem;
    padding: 1.3rem 1.2rem;
  }
  
  button.close img {
    width: 1em;
    padding: 1.3rem 1.2rem;
  }

  .chat {
    grid-area: chat;
    overflow-y: auto;

    scrollbar-width: none;
    background: var(--secondary-light);
    position: relative;
  }
  
  #scroll-to-bottom {
    position: absolute;
    right: 7px;
    bottom: 90px;
    margin-bottom: 3px;
    background: var(--gray-light);
    border: none;
    height: 3em;
    width: 3em;
    cursor: pointer;

    box-shadow: 0 0 10px rgb(var(--black) / 10%);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  #scroll-to-bottom img {
    height: 2em;
    width: 2em;

  }
  
  form {
    grid-area: form;
    display: flex;
    flex-direction: row;
    padding: 1.2rem 1.3rem;
  }
  
  form input {
    flex: 1 1 auto;
    padding: 0.3rem 0.5rem;
  }
  
  form button {
    background: var(--primary);
    border: none;
    color: white;
    padding: 1em 2em;
  }
  button {
    cursor: pointer;
  }
`

@customElement({ name: "chat-component", template, styles })
export class ChatComponent extends FASTElement {
  @observable state = state;
  @observable msg = "";
  @observable isAtBottom = false;

  chatElement!: HTMLElement;
  @attr url!: string;

  @attr bubble!: BubbleComponent;

  @attr({ mode: "boolean" }) visible: boolean = false;

  input !: HTMLInputElement;

  onInputChange(e: Event) {
    this.msg = (e.target as HTMLInputElement).value;
  }
  
  connectedCallback(): void {
    
    super.connectedCallback();
    this.onScroll();
    
  }


  async onSubmit(e: Event) {
    const data = new FormData(e.target as HTMLFormElement);
    (e.target as HTMLFormElement).reset();
    this.state.loading = true;

    this.scrollToLatest();
    await query(data.get('question') as string, this.url);
    this.state.loading = false;
    this.scrollToLatest();

    storeState();
  }


  scrollToLatest(behavior: ScrollBehavior = "smooth") {
    setTimeout(() => {
      this.chatElement.lastElementChild?.scrollIntoView({
        behavior: behavior,
      });
    }, 10);
  }

  async onScroll() {
    this.isAtBottom =      this.chatElement.scrollTop >=      this.chatElement.scrollHeight - this.chatElement.offsetHeight - 10;
  }
}