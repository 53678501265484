import { attr, css, customElement, FASTElement, html, observable } from "@microsoft/fast-element";
import { state } from "./store";
import { Message } from "./api";


import showdown from 'showdown';

const converter = new showdown.Converter();

const template = html<MessageElement>`
<div class="bubble ${x => x.msg.role}" :innerHTML="${x => converter.makeHtml(x.msg.message)}">
</div>
`;
const styles = css`
  .bubble-wrapper {
    display: flex;
    flex-direction: column;
    padding: 12px 25px;
  }
  .bubble {
    padding: 1.2rem 1.3rem;
    margin: 1.2rem 1.3rem;
    --color: rgb(255, 243, 243);
    background: var(--color);
    box-shadow: 0 0 10px rgb(var(--black) / 10%);
    min-width: 5em;
    min-height: 1.2em;
    position: relative;
  }

  .bubble.bot::after {
    content: " ";
    position: absolute;
    width: 0px;
    height: 0px;
    inset: 0px auto auto -19px;
    border-style: solid;
    border-image: none;
    border-width: 22px;
    border-color: var(--color) transparent transparent;
  }
  .bubble.user::after {
    content: " ";
    position: absolute;
    width: 0px;
    height: 0px;
    inset: 0px -20px auto auto;
    border-style: solid;
    border-image: none;
    border-width: 22px;
    border-color: var(--color) transparent transparent transparent;
  }

  .bubble.user {
    align-self: flex-end;
    --color: var(--secondary);
  }

  .bubble.bot {
    align-self: flex-start;

    color: white;
    --color: var(--primary);
  }

  p {
      padding: 0;
      margin: 0;
  }
`;

@customElement({
    name: 'message-element',
    template,
    styles
})
export class MessageElement extends FASTElement {
    @attr msg!: Message;
    
    @observable state = state;
    
}