import { provideFASTDesignSystem } from "@microsoft/fast-components";
import { ChatComponent } from "./chat";
import { MessageElement } from "./message";
import { BubbleComponent } from "./bubble";
import { FontAwesomeIcon } from "./fontawesome";
import { LoadingIndicator } from "./loading";


provideFASTDesignSystem().register(
    BubbleComponent,
    ChatComponent,
    MessageElement,
    FontAwesomeIcon,
    LoadingIndicator,
);