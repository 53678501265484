import { state } from "./store";


interface Source {
    url: string;
    text: string;
}

export interface Message {
    role: "user" | "bot";
    message: string ;
    docs: Source[];
}

interface InitThreadResponse {
    msg: Message;
    threadId: string;

}



export async function query(msg: string, url: string) {
    state.messages.push({
        'role': 'user',
        'message': msg,
        'docs': []
    })
    let answer = null;
    if (state.threadId) {
        answer = await queryThread(state.threadId, msg, url)
    } else {
        let response = await newThread(msg, url);
        state.threadId = response.threadId;
        answer = response.msg;
    }
    
    state.messages.push(answer);
}

async function queryThread(threadId: string, msg: string, url: string): Promise<Message>{
    const form = new FormData();
    form.append("question", msg);
    form.append("threadId", threadId);

    const options = {
      method: 'POST',
      headers: {
        Accept: 'application/json'
        },
      body: form
    };

    let data = await fetch(url, options)
      .then(response => response.json())
    return {
        'docs': data['docs'],
        'message': data['answer'],
        'role': 'bot'
    }

}

async function newThread(msg: string, url: string):  Promise<InitThreadResponse>  {
    const form = new FormData();
    form.append("question", msg);

    const options = {
      method: 'POST',
      headers: {
        Accept: 'application/json'
        },
      body: form
    };


    let data = await fetch(url + "init/", options)
      .then(response => response.json())
    
    return {
        msg: {
            'role': "bot",
            'message': data['answer'],
            "docs": data['docs'],
        },
        threadId: data['threadId'],
    };
}