import { css, customElement, FASTElement, html } from "@microsoft/fast-element"

const template = html`<div class="container mexican-wave"></div>`;

const styles = css`.mexican-wave:before {
  -webkit-animation: mexican-wave .75s infinite ease backwards;
          animation: mexican-wave .75s infinite ease backwards;
  border-radius: 100%;
  content: '';
  height: 10px;
  position: absolute;
  -webkit-transform: translateY(10px);
          transform: translateY(10px);
  width: 10px; }

@-webkit-keyframes mexican-wave {
  0% {
    -webkit-box-shadow: -30px -10px 0 0 var(--primary), -15px -10px 0 0 var(--primary), 0 -10px 0 0 var(--primary), 15px -10px 0 0 var(--primary), 30px -10px 0 0 var(--primary);
            box-shadow: -30px -10px 0 0 var(--primary), -15px -10px 0 0 var(--primary), 0 -10px 0 0 var(--primary), 15px -10px 0 0 var(--primary), 30px -10px 0 0 var(--primary); }
  15% {
    -webkit-box-shadow: -30px -20px 0 0 var(--primary), -15px -10px 0 0 var(--primary), 0 -10px 0 0 var(--primary), 15px -10px 0 0 var(--primary), 30px -10px 0 0 var(--primary);
            box-shadow: -30px -20px 0 0 var(--primary), -15px -10px 0 0 var(--primary), 0 -10px 0 0 var(--primary), 15px -10px 0 0 var(--primary), 30px -10px 0 0 var(--primary); }
  30% {
    -webkit-box-shadow: -30px -10px 0 0 var(--primary), -15px -20px 0 0 var(--primary), 0 -10px 0 0 var(--primary), 15px -10px 0 0 var(--primary), 30px -10px 0 0 var(--primary);
            box-shadow: -30px -10px 0 0 var(--primary), -15px -20px 0 0 var(--primary), 0 -10px 0 0 var(--primary), 15px -10px 0 0 var(--primary), 30px -10px 0 0 var(--primary); }
  45% {
    -webkit-box-shadow: -30px -10px 0 0 var(--primary), -15px -10px 0 0 var(--primary), 0 -20px 0 0 var(--primary), 15px -10px 0 0 var(--primary), 30px -10px 0 0 var(--primary);
            box-shadow: -30px -10px 0 0 var(--primary), -15px -10px 0 0 var(--primary), 0 -20px 0 0 var(--primary), 15px -10px 0 0 var(--primary), 30px -10px 0 0 var(--primary); }
  60% {
    -webkit-box-shadow: -30px -10px 0 0 var(--primary), -15px -10px 0 0 var(--primary), 0 -10px 0 0 var(--primary), 15px -20px 0 0 var(--primary), 30px -10px 0 0 var(--primary);
            box-shadow: -30px -10px 0 0 var(--primary), -15px -10px 0 0 var(--primary), 0 -10px 0 0 var(--primary), 15px -20px 0 0 var(--primary), 30px -10px 0 0 var(--primary); }
  75% {
    -webkit-box-shadow: -30px -10px 0 0 var(--primary), -15px -10px 0 0 var(--primary), 0 -10px 0 0 var(--primary), 15px -10px 0 0 var(--primary), 30px -20px 0 0 var(--primary);
            box-shadow: -30px -10px 0 0 var(--primary), -15px -10px 0 0 var(--primary), 0 -10px 0 0 var(--primary), 15px -10px 0 0 var(--primary), 30px -20px 0 0 var(--primary); }
  100% {
    -webkit-box-shadow: -30px -10px 0 0 var(--primary), -15px -10px 0 0 var(--primary), 0 -10px 0 0 var(--primary), 15px -10px 0 0 var(--primary), 30px -10px 0 0 var(--primary);
            box-shadow: -30px -10px 0 0 var(--primary), -15px -10px 0 0 var(--primary), 0 -10px 0 0 var(--primary), 15px -10px 0 0 var(--primary), 30px -10px 0 0 var(--primary); } }

@keyframes mexican-wave {
  0% {
    -webkit-box-shadow: -30px -10px 0 0 var(--primary), -15px -10px 0 0 var(--primary), 0 -10px 0 0 var(--primary), 15px -10px 0 0 var(--primary), 30px -10px 0 0 var(--primary);
            box-shadow: -30px -10px 0 0 var(--primary), -15px -10px 0 0 var(--primary), 0 -10px 0 0 var(--primary), 15px -10px 0 0 var(--primary), 30px -10px 0 0 var(--primary); }
  15% {
    -webkit-box-shadow: -30px -20px 0 0 var(--primary), -15px -10px 0 0 var(--primary), 0 -10px 0 0 var(--primary), 15px -10px 0 0 var(--primary), 30px -10px 0 0 var(--primary);
            box-shadow: -30px -20px 0 0 var(--primary), -15px -10px 0 0 var(--primary), 0 -10px 0 0 var(--primary), 15px -10px 0 0 var(--primary), 30px -10px 0 0 var(--primary); }
  30% {
    -webkit-box-shadow: -30px -10px 0 0 var(--primary), -15px -20px 0 0 var(--primary), 0 -10px 0 0 var(--primary), 15px -10px 0 0 var(--primary), 30px -10px 0 0 var(--primary);
            box-shadow: -30px -10px 0 0 var(--primary), -15px -20px 0 0 var(--primary), 0 -10px 0 0 var(--primary), 15px -10px 0 0 var(--primary), 30px -10px 0 0 var(--primary); }
  45% {
    -webkit-box-shadow: -30px -10px 0 0 var(--primary), -15px -10px 0 0 var(--primary), 0 -20px 0 0 var(--primary), 15px -10px 0 0 var(--primary), 30px -10px 0 0 var(--primary);
            box-shadow: -30px -10px 0 0 var(--primary), -15px -10px 0 0 var(--primary), 0 -20px 0 0 var(--primary), 15px -10px 0 0 var(--primary), 30px -10px 0 0 var(--primary); }
  60% {
    -webkit-box-shadow: -30px -10px 0 0 var(--primary), -15px -10px 0 0 var(--primary), 0 -10px 0 0 var(--primary), 15px -20px 0 0 var(--primary), 30px -10px 0 0 var(--primary);
            box-shadow: -30px -10px 0 0 var(--primary), -15px -10px 0 0 var(--primary), 0 -10px 0 0 var(--primary), 15px -20px 0 0 var(--primary), 30px -10px 0 0 var(--primary); }
  75% {
    -webkit-box-shadow: -30px -10px 0 0 var(--primary), -15px -10px 0 0 var(--primary), 0 -10px 0 0 var(--primary), 15px -10px 0 0 var(--primary), 30px -20px 0 0 var(--primary);
            box-shadow: -30px -10px 0 0 var(--primary), -15px -10px 0 0 var(--primary), 0 -10px 0 0 var(--primary), 15px -10px 0 0 var(--primary), 30px -20px 0 0 var(--primary); }
  100% {
    -webkit-box-shadow: -30px -10px 0 0 var(--primary), -15px -10px 0 0 var(--primary), 0 -10px 0 0 var(--primary), 15px -10px 0 0 var(--primary), 30px -10px 0 0 var(--primary);
            box-shadow: -30px -10px 0 0 var(--primary), -15px -10px 0 0 var(--primary), 0 -10px 0 0 var(--primary), 15px -10px 0 0 var(--primary), 30px -10px 0 0 var(--primary); } }
    .container {
  position: relative;
   margin: 1.8em 3.3rem;       
    }
`;

@customElement({
    'name': "loading-indicator",
    template,
    styles,
})
export class LoadingIndicator extends FASTElement {

}