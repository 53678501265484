import { observable } from "@microsoft/fast-element";
import { Message, query } from "./api";


class AppState {
    @observable name: string = "Hallo";
    @observable messages: Message[] = [{
        role: "bot",
        message: "Wie kann ich dir helfen?",
        docs: [],
    }];
    @observable loading = false;
    @observable threadId: string | null = null;
}

function loadState() {
    let state = new AppState()
    const stored = sessionStorage.getItem('state');
    if (stored) {
        let data = JSON.parse(stored);
        Object.assign(state, data);
        data.loading = false;
    }
    return state;
}



export const state = loadState();

export function storeState() {
    sessionStorage.setItem('state', JSON.stringify(state))
}