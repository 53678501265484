import { attr, css, customElement, FASTElement, html } from "@microsoft/fast-element";

const template = html`
<i class="fa-solid fa-house"></i>
`

const styles = css``


@customElement({
    name: "fa-icon",
    template,
    styles
}
)
export class FontAwesomeIcon extends FASTElement {
    @attr icon !: string;
}