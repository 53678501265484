import {
    FASTElement,
    attr,
    html,
    customElement,
    css,
    observable,
} from "@microsoft/fast-element";

import messageIcon from './icons/message-regular.svg';
import closeIcon from './icons/xmark-solid.svg'


const template = html<BubbleComponent>`
<button @click="${(x, c) => x.toggle()}">
    <img src="${x => x.isOpen ? closeIcon : messageIcon }" />
</button>
<chat-component :url="${x => x.url}" :bubble="${(x) => x}" ?visible=${(x)=> x.isOpen}
    ></chat-element>
`;
const styles = css`
  button {
    position: fixed;
    bottom: 30px;
    right: 30px;
    border-radius: 100%;
    height: 60px;
    width: 60px;
    border: none;
    box-shadow: 0 0 50px rgb(var(--black) / 10%);
    cursor: pointer;
    font-size: 2rem;
    font-weight: bold;
    background: white;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  button img {
    height: 1em;  
  }
  
`;

@customElement({
    name: "bubble-component",
    template,
    styles,
})
export class BubbleComponent extends FASTElement {
    @observable isOpen: boolean = false;
    @attr url!: string;

    toggle() {
        this.isOpen = !this.isOpen;
    }
}
